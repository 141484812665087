import { useMessages } from "@/composables/chat/messages";
import { useVideoCall } from "@/composables/chat/videocall";
import { useNotifications } from "./chat/notifications";
import { useAudio } from './chat/sound';
import { useUser } from "./user/user";
const { handleNotification } = useNotifications();
const { addMessage, deleteMessage, setConversation,setUnreadMessage, updateOnlineUsers,state } = useMessages();
const { handleIncomingHandshake, handleIceCandidate, endCall, handleCallAnswer, updatePeerStatus } =
  useVideoCall();

const { playNotificationTone } = useAudio()
const { userId } = useUser()

const msgEvents = [
  {
    name: "send-message",
    handler: (msg) => {
      if (msg.user_id != userId) playNotificationTone()
      if(msg.conversation_id == state.currentAssociate.conversation_id || msg.conversation_id == state.currentAssociate.id){
        function IDexists(id) {
          return state.messages.some(function(item) {
            return item.id === id;
          });
        }
        if(!IDexists(msg.id)){
          addMessage(msg)
        }
      }
      else{
        setUnreadMessage(msg)
      }
    },
  },
  {
    name: "delete-message",
    handler: (event) => deleteMessage(event.id),
  },
  {
    name: "pusher:subscription_succeeded",
    handler: () => updateOnlineUsers(),
  },
  {
    name: "pusher:member_added",
    handler: () => updateOnlineUsers(),
  },
  {
    name: "pusher:member_removed",
    handler: () => updateOnlineUsers(),
  },
];

const conversationEvents = [
  {
    name: "conversation-started",
    handler: (group) => setConversation(group),
  },
];

const videoCallPresenceEvents = [
  {
    name: "pusher:subscription_succeeded",
    handler: () => updateOnlineUsers()
  },
  {
    name: "pusher:member_added",
    handler: () => updateOnlineUsers()
  },
  {
    name: "pusher:member_removed",
    handler: () => updateOnlineUsers()
  },
];

const videoCallEvents = [
  {
    name: "client-call-candidate",
    handler: (event) => updatePeerStatus(event),
  },
  {
    name: "client-call-handshake",
    handler: (event) => handleIncomingHandshake(event),
  },
  {
    name: "client-call-answer",
    handler: (event) => handleCallAnswer(event),
  },
  {
    name: "client-call-reject",
    handler: () => endCall(),
  },
  {
    name: "client-call-end",
    handler: (event) =>endCall(event),
  },
  {
    name: "client-candidate",
    handler: (event) => handleIceCandidate(event),
  },
];

const notificationEvents = [
  {
    name: "new-message",
    handler: (event) => handleNotification(event),
  },
];

export {
  msgEvents,
  conversationEvents,
  videoCallPresenceEvents,
  videoCallEvents,
  notificationEvents,
};
