import axios from "@axios";
import { usePusher } from "@/composables/pusher";
import { computed, reactive, readonly,ref } from "@vue/composition-api";
import { useNotifications } from "./notifications";

const { state: pusherState } = usePusher();
const { state: notificationsState } = useNotifications();

const state = reactive({
  currentAssociate:{},
  conversation: {},
  associatedUser: [],
  messageAssociate:[],
  messages: [],
  onlineUsers: [],
  unreadMessages:[],
  groups:[]
});

const chatLoading = ref(false)
const activeUser = ref(false)

const setConversation = (val) => (state.conversation = val);
const setGroup = (val) => (state.groups = val)
const setCurrentAssociate = (val) => (state.currentAssociate = val);
const setAssociatedUser = (val) => (state.associatedUser = val)
const setMessageAssociate = (val) => (state.messageAssociate = val)
const setMessages = (val) => (state.messages = val);
const addMessage = (val) => {state.messages = [...state.messages,val]};
const deleteMessage = (msgId) => (state.messages = state.messages.filter((msg) => msg.id != msgId));
const setUnreadMessage = (msg) => (state.unreadMessages.push(msg))

const updateOnlineUsers = () => {
  const onlineUsers = [];
    let channel = pusherState.pusher.channel(`presence-online-users`);
    channel.members.each((member) => {
      onlineUsers.push({
        id: member.info.id,
      });
    });
  state.onlineUsers = onlineUsers;
};

const associatedUser = computed(() => {
  if (state.associatedUser.length > 0){
    return state.associatedUser;
  }
  return false;
});

const activeConversation = computed(() => {
  if (state.conversation != '') {
    return state.conversation;
  }
  return false;
});

const readMsgs = computed(() => {
  const earliestUnread = notificationsState.notification.earliestUnread;
  if (!earliestUnread) return state.messages;
  const earliestDate = new Date(earliestUnread);
  return state.messages.filter((msg) => new Date(msg.created_at) < earliestDate);
});

const unreadMsgs = computed(() => {
  const earliestUnread = notificationsState.notification.earliestUnread;
  if (!earliestUnread) return [];
  const earliestDate = new Date(earliestUnread);
  return state.messages.filter((msg) => new Date(msg.created_at) > earliestDate);
});

async function openMessage(user) {

  if (state.unreadMessages.some(u => u.conversation_id == user.conversation_id)) {

    for (let i = state.unreadMessages.length - 1; i >= 0; i--) {
      if (state.unreadMessages[i].conversation_id == user.conversation_id) {
        state.unreadMessages.splice(i, 1)
      }
    }
    messageCount(user.id)
  }
  chatLoading.value = true
  activeUser.value = user.conversation_id
  try {
    let conversation_id = user.conversation_id ? user.conversation_id : user.id
    const { data: messages } = await axios.get(`conversations/${conversation_id}`);
    setConversation(conversation_id)
    setMessages(messages);
    setCurrentAssociate(user)
    // updateChannel(`private-${conversation_id}`)
    // setPeerChannel(`private-${conversation_id}`)
  } catch (err) {
    console.log(err);
  }
  finally {
    chatLoading.value = false
    activeUser.value = null
  }
}

const messageCount = (id) => {
  return state.unreadMessages.filter(item => item.conversation_id == id).length
}

async function deleteGroup(id) {
  axios.delete(`/group/delete-group/${id}`)
  let newArray = state.groups.filter(item => item.conversation_id != id)
  setConversation({})
  setCurrentAssociate({})
  setGroup(newArray)
}

export function useMessages() {
  return {
    state: readonly(state),
    associatedUser,
    readMsgs,
    unreadMsgs,
    setConversation,
    setAssociatedUser,
    setMessageAssociate,
    activeConversation,
    updateOnlineUsers,
    addMessage,
    setMessages,
    deleteMessage,
    setCurrentAssociate,
    setUnreadMessage,
    setGroup,
    openMessage,
    messageCount,
    deleteGroup,
    chatLoading,
    activeUser,
  };
}
