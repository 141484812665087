import axios from "@axios";
import { reactive, readonly, computed } from "@vue/composition-api";

const state = reactive({
  history: [],
  wallet: {},
  savedCards: []
})

const setPayments = val => (state.history = val)
const setWalletDetails = val => (state.wallet = val)
const setCards = val => (state.savedCards = val)

const initWallet = async () => {
  const { data: walletDetail } = await axios.get("/wallet")
  const { data: paymentHistory } = await axios.get("/wallet/payments-history")
  if (walletDetail && paymentHistory) {
    setWalletDetails(walletDetail)
    setPayments(paymentHistory)
  }
}

const initTimeTrack = async () => {
  const { data: walletDetail } = await axios.get("/wallet")
  if (walletDetail) {
    setWalletDetails(walletDetail)
  }
}

const haveDoneFirstPayment = computed(() => state.history.filter(history => history.status == 'succeeded').length == 0 ? false : true)

const haveSavedCard = computed(() => state.savedCards.length == 0 ? false : true)


export function useWallet() {
  return {
    state: readonly(state),
    initWallet,
    setPayments,
    setWalletDetails,
    initTimeTrack,
    haveDoneFirstPayment,
    haveSavedCard,
    setCards
  }
}
