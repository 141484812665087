<template>
  <router-link :to="toLink" :class="['countdown-box', isAbsolute ? 'is-absolute' : 'is-relative']"
    v-if="statusType !== 'expired'">
    <div class="countdown-banner">
      <img src="@/assets/images/countdown.jpg" />
    </div>
    <div class="timer">

      <div>
        <div class="day">
          <span class="number">{{ days }}</span> :
          <div class="format">{{ wordString.day }}</div>
        </div>
        <div class="hour">
          <span class="number">{{ hours }}</span> :
          <div class="format">{{ wordString.hours }}</div>
        </div>
        <div class="min">
          <span class="number">{{ minutes }}</span>
          <div class="format">{{ wordString.minutes }}</div>
        </div>
        <!-- <div class="sec">
          <span class="number">{{ seconds }}</span>
          <div class="format">{{ wordString.seconds }}</div>
        </div> -->
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  props: ['starttime', 'endtime', 'trans', 'to', 'absolute'],
  data: function () {
    return {
      timer: "",
      wordString: {},
      start: "",
      end: "",
      interval: "",
      days: "",
      minutes: "",
      hours: "",
      seconds: "",
      message: "",
      statusType: "",
      statusText: "",
      toLink: '',
      isAbsolute: false
    };
  },
  created: function () {
    this.wordString = JSON.parse(this.trans);
    this.toLink = this.to;
    this.isAbsolute = this.absolute
  },
  mounted() {
    this.start = new Date(this.starttime).getTime();
    this.end = new Date(this.endtime).getTime();
    // Update the count down every 1 second
    this.timerCount(this.start, this.end);
    this.interval = setInterval(() => {
      this.timerCount(this.start, this.end);
    }, 1000);
    // console.log(`${this.days}:${this.hours}:${this.minutes}`)
  },
  methods: {
    timerCount: function (start, end) {
      // Get todays date and time
      var now = new Date().getTime();

      // Find the distance between now an the count down date
      var distance = start - now;
      var passTime = end - now;

      if (distance < 0 && passTime < 0) {
        this.message = this.wordString.expired;
        this.statusType = "expired";
        this.statusText = this.wordString.status.expired;
        clearInterval(this.interval);
        return;

      } else if (distance < 0 && passTime > 0) {
        this.calcTime(passTime);
        this.message = this.wordString.running;
        this.statusType = "running";
        this.statusText = this.wordString.status.running;

      } else if (distance > 0 && passTime > 0) {
        this.calcTime(distance);
        this.message = this.wordString.upcoming;
        this.statusType = "upcoming";
        this.statusText = this.wordString.status.upcoming;
      }
    },
    calcTime: function (dist) {
      // Time calculations for days, hours, minutes and seconds
      this.days = Math.floor(dist / (1000 * 60 * 60 * 24));
      this.hours = Math.floor((dist % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      this.minutes = Math.floor((dist % (1000 * 60 * 60)) / (1000 * 60));
      this.seconds = Math.floor((dist % (1000 * 60)) / 1000);
    }

  }
}
</script>

<style >
.countdown-box {
  display: flex;
  background-color: #FAE5E2;
  padding: 0;
  justify-content: center;
  align-items: center;
  padding-right: 10px;
  border-radius: 8px;
  /* position: absolute; */
  right: 8%;
}

.is-absolute {
  position: absolute;
}

.is-relative {
  position: relative;
}

@media screen and (max-width: 786px) {
  .countdown-box {
    flex-direction: column;
    right: 0% !important;
  }
}

.countdown-banner {
  position: relative;
}

.countdown-banner img {
  width: 100%;
  max-width: 400px;
  margin: 0px !important;
  /* position: absolute; */
}

.timer {
  font-size: 22px;
  color: #000;
  text-align: center;
}

.timer .day,
.timer .hour,
.timer .min,
.timer .sec {
  font-size: 22px;
  display: inline-block;
  font-weight: 500;
  text-align: center;
  /* margin: 0 3px; */
}

.timer .day .format,
.timer .hour .format,
.timer .min .format,
.timer .sec .format {
  font-weight: 300;
  font-size: 12px;
  opacity: 0.8;
  /* width: 60px; */
}

.timer .number {
  /* background: rgba(252, 15, 15, 0.53); */
  padding: 0 5px;
  border-radius: 5px;
  display: inline-block;
  width: 40px;
  text-align: center;
}

.timer .message {
  font-size: 14px;
  font-weight: 400;
  margin-top: 5px;
}

.timer .status-tag {
  width: 270px;
  margin: 10px auto;
  padding: 8px 0;
  font-weight: 500;
  color: #000;
  text-align: center;
  border-radius: 15px;
}

.timer .status-tag.upcoming {
  background-color: lightGreen;
}

.timer .status-tag.running {
  background-color: gold;
}

.timer .status-tag.expired {
  background-color: silver;
}
</style>
