<template>
  <!-- If url contains client secret, show the appropriate message before loading app -->
  <!-- Message will contain status about the most recent payment/card update -->
  <div v-if="state.hasSubNotification">
    <v-app>
    </v-app>
  </div>
  <v-app v-else>
    <navbar />

    <v-alert v-if="userState.isPreviewMode" color="primary" class="mb-0" rounded="0" type="info">
      You are currently previewing the website as {{ userName }}.
      <span class="ml-1">
        Any changes you make will be saved.
      </span>
    </v-alert>

    <v-alert class="popup-message" elevation="11" prominent dismissible
      v-if="(((walletDetail.wallet.minutes <= BUY_NOW_POPUP_MIN_MINUTES) || !associatedUser) && isAlertShown && userType == 'client')">
      <ul class="ulList">
        <li class="" v-if="!associatedUser">{{ infoMsg }}</li>
        <li v-if="walletDetail.wallet.minutes <= BUY_NOW_POPUP_MIN_MINUTES && walletDetail.wallet.minutes > 1">
          <span class="no-hours">You Have only <span class="red-color">
              {{ convertMinsToHrsMins(walletDetail.wallet.minutes) }}</span></span>
          <router-link to="/wallet" class="red-color-link">
            <v-icon color="primary" size="20">{{ icons.mdiWalletPlus }}</v-icon>
            Buy Now
          </router-link>
        </li>
        <li v-if="(walletDetail.wallet.minutes <= 1 && userType == 'client')">
          <span class="no-hours">You Have No Hours</span>
          <router-link to="/wallet" class="red-color-link">
            <v-icon color="primary">{{ icons.mdiWalletPlus }}</v-icon>
            Buy Now
          </router-link>
        </li>
      </ul>
    </v-alert>

    <v-alert class="popup-message" elevation="11" prominent dismissible
      v-if="!associatedUser && isAlertShown && userType == 'va'">
      <ul class="ulList">
        <li class="" v-if="!associatedUser">{{ infoMsg }}</li>
      </ul>
    </v-alert>

    <div class="dashboard--layout">

      <navigation />
      <div v-if="state.isLoading" class="loading-box">
        <v-progress-circular color="primary" indeterminate class="loading-spinner" />
      </div>
      <account-disabled-wall v-else-if="showAccountDisabled" />
      <slot v-else />
    </div>

  </v-app>
</template>

<script>
import axios from '@axios'
import { mdiClose, mdiWalletPlus } from '@mdi/js'
import { computed, onMounted, onUnmounted, reactive, ref } from '@vue/composition-api'
import Navbar from '@/components/layout/navbar/Navbar.vue'
import Navigation from '@/components/layout/navigation/Navigation.vue'
import AccountDisabledWall from '@/components/misc/AccountDisabledWall.vue'
import { useMessages } from '@/composables/chat/messages'
import { useNotifications } from '@/composables/chat/notifications'
import { notificationEvents, videoCallPresenceEvents, msgEvents } from '@/composables/event-listeners'
import { usePusher } from '@/composables/pusher'
import { useUser } from '@/composables/user/user'
import { useWallet } from '@/composables/user/wallet'
import { hourManagement } from '@/composables/hours-management.js'

export default {
  name: 'LayoutCustom',
  components: {
    Navbar,
    Navigation,
    AccountDisabledWall,
  },
  setup() {

    const BUY_NOW_POPUP_MIN_MINUTES = ref(120)

    const state = reactive({
      isLoading: true,
      isInfoVisible: true,
      hasSubNotification: true,
    })

    let intervalID

    const { state: userState, userData, userName, userType, setUserData } = useUser()
    const { state: walletDetail, setWalletDetails, setPayments, initTimeTrack } = useWallet()
    const { setAssociatedUser, setMessageAssociate, associatedUser, setGroup } = useMessages()
    const { subscribeToChannel, unsubscribeFromChannel } = usePusher()
    const { setNotification } = useNotifications()
    const { setPricing } = hourManagement()

    let notificationsChannel = 'private-notifications-'
    let storedUser
    const isAlertShown = ref(false)

    async function initApp() {
      try {
        state.isLoading = true
        isAlertShown.value = false

        const { data: userData } = await axios.get('/users/me')
        if (userData.type == 'client') {

          const pricingDetail = await axios.get('/wallet/pricing')
          setPricing(pricingDetail.data)

          await axios.get('/wallet/track')
          const wallet = await axios.get('/wallet')
          const paymentHistory = await axios.get('/wallet/payments-history')
          setWalletDetails(wallet.data)
          setPayments(paymentHistory.data)
        }
        const associate = await axios.get('/users/associate')

        const messageAssociate = await axios.get('/users/message-associate')
        const notifications = await axios.get('/conversations/notifications')
        const groups = await axios.get('/group')

        setUserData(userData)
        setAssociatedUser(associate.data)
        setMessageAssociate(messageAssociate.data)
        setNotification(notifications.data)
        setGroup(groups.data)

        notificationsChannel += userData.id

        subscribeToChannel(notificationsChannel, notificationEvents)
        subscribeToChannel(`presence-online-users`, videoCallPresenceEvents)

        if (associatedUser.value.length > 0) {

          associatedUser.value.forEach(assosiateUser => {
            subscribeToChannel(`private-${assosiateUser.conversation_id}`, [
              ...videoCallPresenceEvents,
              ...msgEvents
            ])
          })

          if (groups.data.length > 0) {
            groups.data.forEach(group => {
              subscribeToChannel(`private-${group.conversation_id}`, [
                ...msgEvents
              ])
            })
          }

          storedUser = JSON.parse(localStorage.getItem('userData'))

          if (storedUser.type == 'client' && associate.data.length > 0) {
            intervalID = setInterval(async () => {
              const res = await axios.get('/wallet/track')
              if (res.data.message) {
                await initTimeTrack()
              }
            }, 60000)
          }
        }
      } catch (err) {
        console.log(err)
      } finally {
        state.isLoading = false
        isAlertShown.value = true
      }
    }

    const convertMinsToHrsMins = (mins) => {
      let h = Math.floor(mins / 60);
      let m = mins % 60;
      h = h < 10 ? '0' + h : h; // (or alternatively) h = String(h).padStart(2, '0')
      m = m < 10 ? '0' + m : m; // (or alternatively) m = String(m).padStart(2, '0')
      return `${h}h:${m}m`;
    }

    onMounted(() => {
      const searchParams = new URLSearchParams(window.location.search)
      if (
        !searchParams.get('payment_intent_client_secret')
        && !searchParams.get('setup_intent_client_secret')
      ) closeSubNotification()
    })

    async function closeSubNotification() {
      state.hasSubNotification = false
      initApp()
    }

    onUnmounted(() => {
      storedUser = JSON.parse(localStorage.getItem('userData'))
      if (!storedUser) clearInterval(intervalID)
      unsubscribeFromChannel(notificationsChannel)
      unsubscribeFromChannel(`presence-online-users`)
      if (associatedUser.value.length > 0) {
        associatedUser.value.forEach(assosiateUser => {
          subscribeToChannel(`private-${assosiateUser.conversation_id}`)
        })
      }
    })


    const showAccountDisabled = computed(() => {
      if (userData.value.is_disabled) return true
      return false
    })

    const infoMsg = computed(() => {
      const msg = []

      if (!associatedUser.value) {
        if (userType.value == 'client') msg.push('No Virtual Assistant Assigned')
        else msg.push('No Client Assigned')
      }

      if (msg.length) return msg.join(' | ')
      return ''
    })

    return {
      state,
      infoMsg,
      associatedUser,
      walletDetail,
      showAccountDisabled,
      closeSubNotification,
      convertMinsToHrsMins,
      userName,
      userState,
      userType,
      icons: {
        mdiClose,
        mdiWalletPlus
      },
      isAlertShown,
      BUY_NOW_POPUP_MIN_MINUTES
    }
  },
}
</script>

<style lang="scss">
.ulList {
  padding-right: 0.5rem;
}

.ulList li {
  padding: 0.2rem 0 0.2rem 0;
}

.no-hours {
  padding-right: 0.5rem;
}

.text {
  font-size: 1.1rem;
}

.red-color {
  color: #f34c57;
  font-weight: 500;
  font-size: 1.2rem;
}

.red-color-link {
  text-decoration: none;
  color: #f34c57 !important;
  font-size: 1rem;
}

.popup-message {
  position: absolute;
  background-color: #fff !important;
  top: 30px;
  right: 35px;
  max-width: 400px;
  width: 100% !important;
  z-index: 999;
  padding: 0.8rem;
  border-radius: 10px;
  font-weight: 500;
}

.loading-spinner {
  /* position: absolute; */
  /* display: flex; */
  /* justify-items: center; */
  position: absolute;
  margin: auto auto;
  // margin-left: auto;
  // margin-right: auto;
  // margin-top: auto;
  // margin-bottom: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

}

.dashboard--layout {
  position: relative;
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.dashboard__content {
  max-height: 100%;
  padding: 2rem;
  flex-grow: 1;
}

.info-bar {
  display: flex;
  flex-direction: row;
  padding: 0.5rem 2rem;
  border-bottom: 1px solid;
  color: white;
  background-color: #f34c57;
}

@media (max-width: 545px) {
  .dashboard__content {
    padding: 1.5rem 1rem;
  }

  .dashboard--layout {
    height: 100%;
    display: block;
  }

  .popup-message {
    position: absolute;
    right: 0;
    top: 1%;
    width: 90% !important;
    z-index: 999;
    padding: 1.2rem;
    border-radius: 10px;
    margin-right: 5%;
    margin-left: 5%;
  }
}
</style>
