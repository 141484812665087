<template>
  <div class="d-flex flex-col align-self-center mx-auto">
    <v-card>
      <v-card-text>
        Your account has been suspended. <br>
        Please contact our support if you think this was a mistake.
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'AccountDisabledWall',
}
</script>

<style></style>
