const tone = require('@/assets/music/tone2.mp3')
var audio = new Audio(tone);


function playNotificationTone(){
  audio.play();
}

export function useAudio(){
  return {
    playNotificationTone
  }
}
