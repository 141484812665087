import { ref } from '@vue/composition-api'

const min_hour = ref(); // minimum hours
const max_hour = ref(); // maximums purchaseable hours
const hour_slider_step = ref(10) //slider step
const hour_cost = [11, 10, 9]; // hour pricing {80-159:hour_cost[0]},{160-479:hour_cost[1]},{479+:hour_cost[2]}

const pricingDetail = ref()

// give cost of hour where hour fall in

// const hourCost = (hours) => {
//   if (hours < 80 || !hours) return
//   if (hours <= 159) return hour_cost[0]
//   if (hours >= 160 && hours <= 479) return hour_cost[1]
//   if (hours > 479) return hour_cost[2]
// }

// Calculate total amount based on hourly rate

// const totalBillAmount = (hours) => {
//   if (hours < 80 || !hours) return
//   if (hours <= 159) return hours * hourCost(hours)
//   if (hours >= 160 && hours <= 479) return hours * hourCost(hours)
//   if (hours > 479) return hours * hourCost(hours)
// }


const setPricing = (data) => {
  pricingDetail.value = data
  const result = data.reduce((acc, obj) => {
    const { min_hours, max_hours } = obj;

    // Update the minimum value
    if (min_hours < acc.min) {
      acc.min = min_hours;
    }

    // Update the maximum value
    if (max_hours > acc.max) {
      acc.max = max_hours;
    }

    return acc;
  }, { min: Infinity, max: -Infinity });

  const { min, max } = result
  min_hour.value = min
  max_hour.value = max
}

const hourCost = (hours) => {
  if (hours < 0 || !hours) return
  for (let index = 0; index < pricingDetail.value.length; index++) {
    if (hours >= pricingDetail.value[index].min_hours && hours <= pricingDetail.value[index].max_hours) {
      return pricingDetail.value[index].price
    }
  }
}

const totalBillAmount = (hours) => {
  if (hours < 0 || !hours) return
  for (let index = 0; index < pricingDetail.value.length; index++) {
    if (hours >= pricingDetail.value[index].min_hours && hours <= pricingDetail.value[index].max_hours) {
      return hours * pricingDetail.value[index].price
    }
  }
}

export function hourManagement() {
  return {
    min_hour,
    max_hour,
    hour_cost,
    hour_slider_step,
    hourCost,
    totalBillAmount,
    setPricing,
    pricingDetail
  }
}
